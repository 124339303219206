import React from "react";
import Table, { TableContent, TableRow } from "./Table";
import { TitledCard } from "../../../components/organisms/TitledCard";
import { Plan } from "../../../common/types/apimodel";
import Checkbox from "@material-ui/core/Checkbox";

export interface PlanSettingTableContent {
  id: number;
  name: string;
  interval: 1 | 2 | 3 | 4; // 1: 日, 2: 週, 3: 月, 4: 年
  price: number;
}

export interface PlanSettingTableProps {
  plans: Plan[];
  viewablePlanIds: string[];
  setViewablePlanIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const intervalToString: (interval: 1 | 2 | 3 | 4) => string = (interval) => {
  switch (interval) {
    case 1:
      return "日";
    case 2:
      return "週";
    case 3:
      return "月";
    case 4:
      return "年";
  }
};

const PlanSettingTable: React.FC<PlanSettingTableProps> = ({
  plans,
  viewablePlanIds,
  setViewablePlanIds,
}) => {
  const handleOnPlanCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let target = event.target.value;
    if (viewablePlanIds.includes(target)) {
      setViewablePlanIds(viewablePlanIds.filter((id) => id !== target));
    } else {
      setViewablePlanIds(viewablePlanIds.concat([event.target.value]));
    }
  };
  const header: TableContent = {
    items: ["", "プラン名", "課金間隔", "金額"],
  };
  const contents: TableRow[] = plans.map(
    ({ planId, name, price, interval }) => {
      return {
        id: planId,
        items: [
          <Checkbox
            value={planId}
            checked={viewablePlanIds.includes(planId)}
            onChange={handleOnPlanCheckBoxChange}
          />,
          name,
          intervalToString(interval),
          `¥${price.toLocaleString()}`,
        ],
      };
    }
  );
  return (
    <TitledCard title={"公開対象プラン"}>
      <Table header={header} contents={contents} />
    </TitledCard>
  );
};

export default PlanSettingTable;
