import React from "react";
import { Plan } from "../../../common/types/apimodel";
import { Button } from "../../atoms/Button";
import { PlanSettingTable } from "../Table";
import styled from "styled-components";

interface PlanSettingModalProps {
  disclosureRange: string;
  isArticleTitleFilled: boolean;
  isArticleBodyFilled: boolean;
  plans: Plan[];
  viewablePlanIds: string[];
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setViewablePlanIds: React.Dispatch<React.SetStateAction<string[]>>;
  onSubmintButtonClick: () => void;
}

const PlanSettingModal: React.FC<PlanSettingModalProps> = ({
  disclosureRange,
  isArticleTitleFilled,
  isArticleBodyFilled,
  plans,
  viewablePlanIds,
  setIsModalOpen,
  setViewablePlanIds,
  onSubmintButtonClick
}) => {
  const getIsValid = (
    isArticleBodyFilled: boolean,
    isArticleTitleFilled: boolean,
    disclosureRange: string
  ) => {
    if (disclosureRange === "published")
      return isArticleTitleFilled && isArticleBodyFilled;
    return true;
  };

  const handleOnSubmitButtonClick = () => {
    setViewablePlanIds([]);
    onSubmintButtonClick();
    setIsModalOpen(false);
  };

  return (
    <>
      {getIsValid(
        isArticleBodyFilled,
        isArticleTitleFilled,
        disclosureRange
      ) ? (
        <div>
          <h2>公開プラン設定</h2>
          {plans.length === 0 ? (
            <div>選択できるプランがありません。本記事は全体公開されます。</div>
          ) : (
            <PlanSettingTable
              plans={plans}
              viewablePlanIds={viewablePlanIds}
              setViewablePlanIds={setViewablePlanIds}
            />
          )}
          <ButtonArea>
            <ButtonWrapper>
              <Button onClick={handleOnSubmitButtonClick} containerStyle="primary">
                {disclosureRange === "draft" ? "下書き" : "公開"}投稿
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button onClick={() => setIsModalOpen(false)} containerStyle="secondary">
                閉じる
              </Button>
            </ButtonWrapper>
          </ButtonArea>
        </div>
      ) : (
        <div>
          <div>公開する場合はタイトルと本文を入力して下さい</div>
          <Button onClick={() => setIsModalOpen(false)} containerStyle="secondary">
            閉じる
          </Button>
        </div>
      )}
    </>
  );
};

export default PlanSettingModal;

const ButtonArea = styled.div`
  margin-top: 5px;
`;

const ButtonWrapper = styled.span`
  margin: 5px;
`;
