import { useState } from "react";
import { base64Encode } from "../utils/base64Encode";

export const useFile = (
  initValue: Image | null | (() => Image)
): [
  Image | null,
  (files: File[] | null) => void,
  (image: Image | null) => void
] => {
  const [image, setImage] = useState<Image | null>(initValue);
  const handleAddImage = async (files: File[] | null) => {
    if (files === null) {
      setImage(null);
      return;
    }
    if (!(files && 0 < files.length)) {
      return;
    }
    const file = files[0];
    const encodedFile = await base64Encode(file);
    const uri = window && window.URL.createObjectURL(file);
    setImage({ file: encodedFile, name: file.name, uri, originFile: file });
  };

  return [image, handleAddImage, setImage];
};

export type Image = {
  file: string;
  name: string;
  uri: string;
  originFile?: File;
  imageId?: string;
};
