import * as React from "react";
import { useCallback } from "react";
import { getAsync } from "../utils/apiClient";
import { isAuthorized } from "../utils/auth";
import { GetAccountResponse } from "../common/types/apimodel";
export const accountDefault: AccountProps = {
  account: {
    name: "",
    email: "",
  },
  updateAccount: () => {},
  loaded: false,
};
export const AccountContext = React.createContext<AccountProps>(accountDefault);
const AccountStore: React.FC = ({ children }) => {
  const fetchAccount = useCallback(async () => {
    try {
      const response = await getAsync<GetAccountResponse>("/account");
      setAccount((prev: AccountProps) => {
        return { ...prev, loaded: true, account: response.data };
      });
    } catch (e) {
      //
    }
  }, []);
  const updateAccountStore = useCallback(async () => {
    const response = await getAsync<GetAccountResponse>("/account");
    const updated: GetAccountResponse = {
      ...response.data,
    };
    setAccount((prev: AccountProps) => {
      return { ...prev, account: updated };
    });
  }, []);
  const [account, setAccount] = React.useState<AccountProps>({
    ...accountDefault,
    updateAccount: updateAccountStore,
  });
  const authorized = isAuthorized();
  React.useEffect(() => {
    if (authorized) {
      fetchAccount();
    }
  }, [fetchAccount, authorized]);
  return (
    <AccountContext.Provider value={account}>
      {children}
    </AccountContext.Provider>
  );
};
export default AccountStore;
export interface AccountProps {
  account: GetAccountResponse;
  loaded: boolean;
  updateAccount: () => void;
}
