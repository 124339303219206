import React, { useEffect, useState } from "react";
import EditArticleTemplate from "../../templates/EditArticleTemplate";
import { getAsync } from "../../../utils/apiClient";
import {
  GetArticleDetailResponse,
  GetPlansResponse,
  PutArticleRequest,
  Plan
} from "../../../common/types/apimodel";
import { useFile, Image } from "../../../hooks";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import { useParams } from "react-router-dom";
import { putAsync } from "../../../utils/apiClient";

const EditArticle: React.FC = () => {
  const { articleId } = useParams<ArticleParam>();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [viewablePlanIds, setViewablePlanIds] = useState<string[]>([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [file, handleAddImage, setImage] = useFile(null);
  const [title, setTitle] = useState("");
  const [disclosureRange, setDisclosureRange] = useState("draft");

  useEffect(() => {
    const getPlansAsync = async () => {
      const response = await getAsync<GetPlansResponse>("/plans");
      setPlans(response.data.plans);
    };
    getPlansAsync();
  }, []);

  useEffect(() => {
    const getArticleAsync = async () => {
      const response = await getAsync<GetArticleDetailResponse>(
        `/articles/${articleId}`
      );
      const article = response.data;
      if (article.planIds) setViewablePlanIds(article.planIds);
      const contentState = convertFromRaw(JSON.parse(article.body));
      setEditorState(EditorState.createWithContent(contentState));
      setTitle(article.title);
      setDisclosureRange(article.isDraft ? "draft" : "published");
      const defaultHeaderImage: Image = {
        file: "",
        name: "",
        uri: article.imageUri
      };
      setImage(defaultHeaderImage);
    };
    getArticleAsync();
  }, [articleId, setImage]);

  const putArticleAsync = async () => {
    const param: PutArticleRequest = {
      articleId: parseInt(articleId),
      title,
      image: file?.file,
      body: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      viewablePlanIds,
      isDraft: disclosureRange === "draft"
    };
    await putAsync<PutArticleRequest>(`/articles/${articleId}`, param);
  };

  return (
    <EditArticleTemplate
      plans={plans}
      setPlans={setPlans}
      viewablePlanIds={viewablePlanIds}
      setViewablePlanIds={setViewablePlanIds}
      editorState={editorState}
      setEditorState={setEditorState}
      file={file}
      setFile={handleAddImage}
      title={title}
      setTitle={setTitle}
      disclosureRange={disclosureRange}
      setDisclosureRange={setDisclosureRange}
      sendArticleAsync={putArticleAsync}
    />
  );
};

export default EditArticle;

interface ArticleParam {
  articleId: string;
}
