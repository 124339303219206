import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./ArticleEditor.css";

interface ArticleEditorProps {
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
}

const ArticleEditor: React.FC<ArticleEditorProps> = ({
  editorState,
  setEditorState
}) => {
  const onEditorStateChange = (editorState: EditorState) =>
    setEditorState(editorState);

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="article-editor-wrapper"
        editorClassName="article-editor"
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "colorPicker",
            "history",
            "link",
            "embedded",
            "image"
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true }
        }}
      />
    </div>
  );
};

export default ArticleEditor;
