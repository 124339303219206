import React from "react";
import styled from "styled-components";
import { SideBar } from "../organisms/SideBar";
import TopMenu from "../organisms/TopMenu/TopMenu";

const AdminTemplate: React.FC = ({ children }) => {
  return (
    <>
      <SideBar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopMenu />
          <ContentWrapper>{children}</ContentWrapper>
        </div>
      </div>
    </>
  );
};

export default AdminTemplate;

const ContentWrapper = styled.div`
  padding: 0 24px 24px;
`;
