import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { LoginRequest, LoginResponse } from "../../../types/models";
import { postAsync } from "../../../utils/apiClient";
import { setToken } from "../../../utils/auth";
import { AccountContext } from "../../../store/AccountContext";

const Login: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<LoginRequest>();
  const history = useHistory();
  const { updateAccount } = useContext(AccountContext);
  const [error, setError] = useState("");
  const onSubmit = handleSubmit(async ({ email, password }) => {
    try {
      const { status, data } = await postAsync<LoginResponse>("/login", {
        email,
        password
      });

      if (status === 200) {
        setToken(data.token!);
        updateAccount();
        history.push("/");
      }
    } catch (e) {
      const errorMessage = e.response.data?.error?.description;
      if (errorMessage) {
        setError(errorMessage);
      }
    }
  });

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-9 col-lg-9 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-12">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">
                        {process.env.REACT_APP_BLAND_NAME}管理画面
                      </h1>
                    </div>
                    <form onSubmit={onSubmit}>
                      <TextField
                        fullWidth
                        name="email"
                        error={Boolean(errors.email)}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "メールアドレスを入力してください"
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "メールアドレスの形式が違います"
                          }
                        })}
                        helperText={errors?.email?.message}
                        label="メールアドレス"
                      />
                      <TextField
                        fullWidth
                        label="パスワード"
                        error={Boolean(errors.password)}
                        inputRef={register({
                          required: {
                            value: true,
                            message: "パスワードを入力してください"
                          }
                        })}
                        helperText={errors?.password?.message}
                        type="password"
                        name="password"
                      />
                      <ButtonContainer>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          type="submit"
                        >
                          ログイン
                        </Button>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                      </ButtonContainer>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

const ButtonContainer = styled.div`
  min-width: 40px;
  min-height: 40px;
  padding: 10px 0px;
  font-size: 16px;
`;

const ErrorMessage = styled.p`
  margin-top: 16px;
  color: red;
`;
