import React from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { isAuthorized, setRedirectURL } from "../../utils/auth";

const PrivateRoute: React.FC<RouteProps> = ({ ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  React.useEffect(() => {
    if (!isAuthorized()) {
      setRedirectURL(`${location.pathname}${location.search}`);
      history.push("/login");
    }
  }, [history, location.pathname, location.search]);

  return <Route {...rest} />;
};
export default PrivateRoute;
