import React from "react";
import styled from "styled-components";
import { Article } from "../../../common/types/apimodel";
import Table, { TableContent, TableRow } from "./Table";
import { TitledCard } from "../../../components/organisms/TitledCard";
import { Link } from "react-router-dom";
import { ConfirmDialog } from "../Dialog";
import { deleteAsync } from "../../../utils/apiClient";

interface ArticleTableProps {
  articles: Article[];
}

const noImageUrl =
  "https://www.shoshinsha-design.com/wp-content/uploads/2016/10/%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%BC%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%88-2016-10-05-0.41.12.png";

const ArticleTable: React.FC<ArticleTableProps> = ({ articles }) => {
  const header: TableContent = {
    items: ["画像", "タイトル", "ステータス", "削除"],
  };

  const [deleteArticleIds, setDeleteArticleIds] = React.useState<number[]>([]);

  const handleDelete = async (articleId: number) => {
    await deleteAsync(`/articles/${articleId}`);
  };

  const contents: TableRow[] = articles
    .filter((a) => !deleteArticleIds.includes(a.articleId))
    .map(({ articleId, title, imageUri, isDraft }) => {
      return {
        id: articleId,
        items: [
          <Link to={`/articles/${articleId}`}>
            <ArticleThumbnail
              src={imageUri === "" ? noImageUrl : imageUri}
              alt="header image"
            />
          </Link>,
          title,
          isDraft ? "下書き" : "公開中",
          <ConfirmDialog
            onAgree={async () => {
              await handleDelete(articleId);
              setDeleteArticleIds([articleId, ...deleteArticleIds]);
            }}
            confirmationText={`${title}を削除しますか？`}
          >
            削除
          </ConfirmDialog>,
        ],
      };
    });
  return (
    <TitledCard title={"記事一覧"}>
      <Table header={header} contents={contents} />
    </TitledCard>
  );
};

const ArticleThumbnail = styled.img`
  width: 190px;
  height: 100px;
`;

export default ArticleTable;
