import React, { useEffect } from "react";
import styled from "styled-components";
import AdminTemplate from "../../../templates/AdminTemplate";
import { TextField, Select, MenuItem, Button } from "@material-ui/core/";
import { useForm, Controller } from "react-hook-form";
import { postAsync } from "../../../../utils/apiClient";
import {
  PostPlanRequest,
  PostPlanResponse
} from "../../../../common/types/apimodel";
import { PlanCard } from "../../../molecules/PlanCard";
import { useHistory } from "react-router-dom";

const Plans: React.FC = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, control, watch } = useForm<
    PostPlanRequest
  >();
  const watchAll = watch();
  useEffect(() => {}, [watchAll]);

  const onSubmit = handleSubmit(
    async ({ name, description, featuredMessage, interval, price }) => {
      const param: PostPlanRequest = {
        name,
        description,
        featuredMessage,
        interval,
        price: Number(price)
      };
      const { status } = await postAsync<PostPlanResponse>("/plans", param);

      if (status === 200) {
        history.push("/plans");
      }
    }
  );

  return (
    <AdminTemplate>
      <h1 className="h3 mb-2 text-gray-800">プラン新規作成</h1>
      <form onSubmit={onSubmit}>
        <InputWrapper>
          <TextField
            fullWidth
            name="name"
            error={Boolean(errors.name)}
            inputRef={register({
              required: {
                value: true,
                message: "プラン名称を入力してください"
              }
            })}
            helperText={errors?.name?.message}
            label="プラン名称"
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            fullWidth
            rowsMax={4}
            multiline={true}
            name="description"
            error={Boolean(errors.description)}
            inputRef={register({
              required: {
                value: true,
                message: "プランの詳細を入力してください"
              },
              maxLength: {
                value: 512,
                message: "プランの詳細は512文字以下で入力してください"
              },
              validate: {
                maxRows: value =>
                  value.split("\n").length < 5 ||
                  "プランの詳細は４行以内で入力してください"
              }
            })}
            helperText={errors?.description?.message}
            label="プラン詳細"
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            fullWidth
            name="featuredMessage"
            error={Boolean(errors.featuredMessage)}
            inputRef={register({
              maxLength: {
                value: 128,
                message: "アピール文128文字以下で入力してください"
              }
            })}
            helperText={errors?.featuredMessage?.message}
            label="アピール文"
          />
        </InputWrapper>
        <SelectWrapper>
          <Controller
            as={
              <Select>
                <MenuItem value={1}>日</MenuItem>
                <MenuItem value={2}>週</MenuItem>
                <MenuItem value={3}>月</MenuItem>
                <MenuItem value={4}>年</MenuItem>
              </Select>
            }
            name="interval"
            control={control}
            defaultValue={1}
          />
        </SelectWrapper>
        <InputWrapper>
          <TextField
            fullWidth
            name="price"
            type="number"
            error={Boolean(errors.price)}
            inputRef={register({
              required: {
                value: true,
                message: "メールアドレスを入力してください"
              },
              max: {
                value: 1000000,
                message: "100万円以内で入力してください"
              },
              min: {
                value: 0,
                message: "0円以上を入力してください"
              },
              pattern: {
                value: /\d/,
                message: "数値を入力して下さい"
              }
            })}
            helperText={errors?.price?.message}
            label="価格"
          />
        </InputWrapper>
        <Button type="submit" variant="outlined" color="primary">
          登録する
        </Button>
      </form>
      <PreviewWrapper>
        <PreviewTitle>プレビュー</PreviewTitle>
        <PlanCard
          name={watchAll.name}
          price={watchAll.price || 0}
          description={watchAll.description}
          featuredMessage={watchAll.featuredMessage}
          interval={watchAll.interval}
          planId={"0"}
        />
      </PreviewWrapper>
    </AdminTemplate>
  );
};

export default Plans;

const InputWrapper = styled.div`
  margin: 16px 0;
  max-width: 360px;
`;

const SelectWrapper = styled.div`
  margin: 24px 0 16px;
`;

const PreviewWrapper = styled.div`
  padding: 24px 0;
`;

const PreviewTitle = styled.h2`
  font-size: 16px;
  margin-bottom: 8px;
`;
