import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface SideBarSectionItemProps {
  title: string;
  to: string;
  isActive: boolean;
}

const SideBarSectionItem: React.FC<SideBarSectionItemProps> = ({
  title,
  to,
  isActive,
}) => {
  return (
    <li>
      {/* <SideBarNavigation to={to} isactive={isActive}> */}
      <SideBarNavigation to={to}>
        <SideBarNavigationHover>{title}</SideBarNavigationHover>
      </SideBarNavigation>
    </li>
  );
};

export default SideBarSectionItem;

// const SideBarNavigation = styled(Link)<{ isactive: boolean }>`
// color: ${props => (props.isactive ? "#fff" : "rgba(255, 255, 255, .8)")};
// ${props => (props.isactive ? "font-weight: 700;" : "")}
const SideBarNavigation = styled(Link)`
  color: #fff;
  display: block;
  width: 100%;
  text-align: left;
  width: 14rem;
  position: relative;
  margin: 16px 0;
`;

const SideBarNavigationHover = styled.span`
  ${SideBarNavigation}:hover & {
    color: #fff;
    font-weight: 700;
  }
  padding-left: 16px;
`;
