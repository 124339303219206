import React from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/StarBorder";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none"
    }
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700]
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2)
  },
  description: {
    whiteSpace: "pre-line"
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  }
}));

const getIntervalTxt = (interval: number): string => {
  switch (interval) {
    case 1:
      return "日";
    case 2:
      return "週";
    case 3:
      return "月";
    case 4:
      return "年";
  }
  return "";
};
const Pricing: React.FC<PricingProps> = ({
  planId,
  name,
  description,
  featuredMessage,
  price,
  buttonVariant = "outlined",
  buttonText = "登録する",
  interval
}) => {
  const classes = useStyles();
  return (
    <Wrapper>
      <Card>
        <CardHeader
          title={name}
          subheader={featuredMessage}
          titleTypographyProps={{ align: "center" }}
          subheaderTypographyProps={{ align: "center" }}
          action={!!featuredMessage ? <StarIcon /> : null}
          className={classes.cardHeader}
        />
        <CardContent>
          <div className={classes.cardPricing}>
            <Typography component="h2" variant="h3" color="textPrimary">
              {price.toLocaleString()}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              円/{getIntervalTxt(interval)}
            </Typography>
          </div>
          <ul>
            <Typography
              component="li"
              variant="subtitle1"
              align="center"
              className={classes.description}
            >
              {description}
            </Typography>
          </ul>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant={buttonVariant}
            color="primary"
            onClick={() => {
              const next = window.location.origin.replace("admin.", "");
              window.location.href = `${next}/signup?planId=${planId}`;
            }}
          >
            {buttonText}
          </Button>
        </CardActions>
      </Card>
    </Wrapper>
  );
};
export default Pricing;

export interface PricingProps {
  name: string;
  price: number;
  featuredMessage?: string;
  description: string;
  interval: number;
  planId: string;
  buttonText?: string;
  buttonVariant?: "outlined" | "contained";
}

const Wrapper = styled.div`
  margin: 8px 0;
  max-width: 360px;
`;
