import React from "react";

const Button: React.FC<ButtonProps> = ({ onClick, containerStyle, children }) => {
  return (
    <div className={`btn-shadow btn btn-${containerStyle}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default Button;

interface ButtonProps {
  onClick: () => void;
  containerStyle: "info" | "primary" | "dark" | "secondary";
}
