import React from "react";
import Table, { TableContent, TableRow } from "./Table";
import { TitledCard } from "../../../components/organisms/TitledCard";
import { ConfirmDialog } from "../Dialog";
import { deleteAsync } from "../../../utils/apiClient";

export interface UserTableContent {
  id: number;
  name: string;
  email: string;
  planName?: string;
}

export interface UserTableProps {
  objects: UserTableContent[];
}

const UserTable: React.FC<UserTableProps> = ({ objects }) => {
  const [deleteUserIds, setDeleteUserIds] = React.useState<number[]>([]);
  const header: TableContent = {
    items: ["名前", "メールアドレス", "契約プラン", "解約"]
  };
  const contents: TableRow[] = objects
    .filter(a => !deleteUserIds.includes(a.id))
    .map(({ id, name, email, planName }) => {
      return {
        id: id,
        items: [
          name,
          email,
          planName ?? "無料プラン",
          <ConfirmDialog
            onAgree={async () => {
              await deleteAsync(`/users/${id}`);
              setDeleteUserIds([id, ...deleteUserIds]);
            }}
            confirmationText={`${name}の解約を行いますか？`}
          >
            解約
          </ConfirmDialog>
        ]
      };
    });
  return (
    <TitledCard title={"ユーザー"}>
      <Table header={header} contents={contents} />
    </TitledCard>
  );
};

export default UserTable;
