export function base64Encode(blob: Blob): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        const base64String = reader.result.substr(
          reader.result.indexOf(",") + 1
        );
        resolve(base64String);
      }
    };
    reader.readAsDataURL(blob);
  });
}
