import React from "react";
import styled from "styled-components";

const TitledCard: React.FC<TitledCardProps> = ({ title, children }) => {
  return (
    <Root>
      <Header>
        <HeaderTitle>{title}</HeaderTitle>
      </Header>
      <Body>{children}</Body>
    </Root>
  );
};

interface TitledCardProps {
  title: string;
}

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
`;
const Header = styled.div`
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

const HeaderTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: cadetblue;
  font-family: sans-serif;
  margin: 0;
  vertical-align: baseline;
`;

const Body = styled.div`
  flex: 1 1 auto;
  padding: 1.25rem;
`;

export default TitledCard;
