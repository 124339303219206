import React, { useState } from "react";
import EditArticleTemplate from "../../templates/EditArticleTemplate";
import { Plan, PostArticleRequest } from "../../../common/types/apimodel";
import { useFile } from "../../../hooks";
import { EditorState, convertToRaw } from "draft-js";
import { postAsync } from "../../../utils/apiClient";

const NewArticle: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [viewablePlanIds, setViewablePlanIds] = useState<string[]>([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [file, setFile] = useFile(null);
  const [title, setTitle] = useState("");
  const [disclosureRange, setDisclosureRange] = useState("draft");

  const postArticleAsync = async () => {
    const param: PostArticleRequest = {
      title,
      image: file?.file,
      body: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      viewablePlanIds,
      isDraft: disclosureRange === "draft",
    };
    await postAsync<PostArticleRequest>("/articles", param);
  };

  return (
    <EditArticleTemplate
      plans={plans}
      setPlans={setPlans}
      viewablePlanIds={viewablePlanIds}
      setViewablePlanIds={setViewablePlanIds}
      editorState={editorState}
      setEditorState={setEditorState}
      file={file}
      setFile={setFile}
      title={title}
      setTitle={setTitle}
      disclosureRange={disclosureRange}
      setDisclosureRange={setDisclosureRange}
      sendArticleAsync={postArticleAsync}
    />
  );
};

export default NewArticle;
