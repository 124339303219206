import React from "react";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { Image } from "../../../hooks";

interface ImageUploaderProps {
  file: Image | null;
  setFile: (files: File[] | null) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ file, setFile }) => {
  const handleOnDrop = (files: File[]) => {
    setFile(files);
  };

  const onImagePreviewDeleteClick = () => {
    setFile(null);
  };

  return (
    <div style={{ margin: "5px auto" }}>
      {!file || file.uri === "" ? (
        <>
          <Dropzone onDrop={handleOnDrop} accept="image/*">
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <AddPhotoAlternateIcon />
                </div>
              </section>
            )}
          </Dropzone>
        </>
      ) : (
        <ImagePreviewContainer>
          <ImagePreview src={file?.uri} alt="header" />
          <ImagePreviewDelete onClick={onImagePreviewDeleteClick}>
            閉じる
          </ImagePreviewDelete>
        </ImagePreviewContainer>
      )}
    </div>
  );
};

export default ImageUploader;

const ImagePreviewContainer = styled.div`
  width: 80%;
  position: relative;
`;

const ImagePreview = styled.img`
  width: 100%;
`;

const ImagePreviewDelete = styled.div`
  position: absolute;
  text-align: center;
  vertical-align: middle;
  width: 50px;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  background-color: rgba(34, 34, 34, 0.2);
  top: 1px;
  right: 1px;
  z-index: 1;
`;
