import React from "react";
import styled from "styled-components";
import { Plan } from "../../../common/types/apimodel";
import { PlanCard } from "../../molecules/PlanCard";

const PlanCardList: React.FC<PlanCardListProps> = ({ planList }) => {
  return (
    <Wrapper>
      {planList.map(plan => (
        <PlanCard
          planId={plan.planId}
          name={plan.name}
          description={plan.description}
          featuredMessage={plan.featuredMessage}
          interval={plan.interval}
          price={plan.price}
        />
      ))}
    </Wrapper>
  );
};

export default PlanCardList;

interface PlanCardListProps {
  planList: Plan[];
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  @media (min-width: 993px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  grid-gap: 8px 16px;
`;
