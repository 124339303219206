import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AdminTemplate from "../../templates/AdminTemplate";
import { getAsync } from "../../../utils/apiClient";
import { GetPlansResponse, Plan } from "../../../common/types/apimodel";
import { PlanCardList } from "../../organisms/PlanCardList";

const Plans: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [planList, setPlanList] = useState<Plan[]>([]);

  useEffect(() => {
    const getPlansAsync = async () => {
      const response = await getAsync<GetPlansResponse>("/plans");
      setPlanList(response.data.plans);
    };
    getPlansAsync();
    if (isLoading) setIsLoading(false);
  }, [isLoading]);

  return (
    <AdminTemplate>
      <Title>プラン一覧</Title>
      <Content>
        {isLoading ? (
          <div>ロード中です</div>
        ) : planList.length > 0 ? (
          <PlanCardList planList={planList} />
        ) : (
          <div>登録されているプランが存在しません</div>
        )}
      </Content>
      <div>
        <Link to={"/plans/new"}>新規プランを登録する</Link>
      </div>
    </AdminTemplate>
  );
};

export default Plans;

const Title = styled.h1`
  font-size: 20px;
`;

const Content = styled.div`
  padding: 8px 0 16px;
`;
