import React from "react";
import { useHistory } from "react-router-dom";

const AddButton: React.FC<AddButtonProps> = ({ href, children }) => {
  const history = useHistory();
  return (
    <div
      className="btn-shadow btn btn-info"
      onClick={() => {
        history.push(href);
      }}
    >
      <span className="btn-icon-wrapper pr-2 opacity-7">
        <i className="fa fa-plus-square fa-w-20"></i>
      </span>
      {children}
    </div>
  );
};

export default AddButton;

interface AddButtonProps {
  href: string;
}
