import React, { useState, useEffect } from "react";
import TopCard from "../../atoms/TopCard/TopCard";
import UserTable from "../../molecules/Table/UserTable";
import AdminTemplate from "../../templates/AdminTemplate";
import { getAsync } from "../../../utils/apiClient";
import { GetUserResponse, User } from "../../../common/types/apimodel";

const Users: React.FC = () => {
  const [userList, setUserList] = useState<User[]>([]);
  useEffect(() => {
    const getUsersAsync = async () => {
      const response = await getAsync<GetUserResponse>("/users");
      setUserList(response.data.users);
    };
    getUsersAsync();
  }, []);

  return (
    <AdminTemplate>
      <h1 className="h3 mb-2 text-gray-800">ユーザー</h1>
      <div className="row">
        <TopCard
          title="ユーザー"
          text={userList.length.toString()}
          icon="user"
          class="danger"
        />
      </div>
      <UserTable objects={userList} />
    </AdminTemplate>
  );
};

export default Users;
