import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const NotificationDialog: React.FC<NotificationDialogProps> = ({
  onAgree,
  notificationText,
  isOpen,
  setIsOpen,
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {notificationText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onAgree();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationDialog;

interface NotificationDialogProps {
  onAgree: () => void;
  notificationText: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
