import React, { useEffect, useState } from "react";
import AdminTemplate from "../../templates/AdminTemplate";
import { getAsync } from "../../../utils/apiClient";
import { Article, GetArticlesResponse } from "../../../common/types/apimodel";
import TopCard from "../../atoms/TopCard/TopCard";
import { ArticleTable } from "../../molecules/Table";
import { AddButton } from "../../atoms/Button";
import styled from "styled-components";

const Articles: React.FC = () => {
  const [articleList, setArticleList] = useState([] as Article[]);
  useEffect(() => {
    const getArticlesAsync = async () => {
      const response = await getAsync<GetArticlesResponse>("/articles");
      setArticleList(response.data.articles);
    };
    getArticlesAsync();
  }, []);
  return (
    <AdminTemplate>
      <h1 className="h3 mb-2 text-gray-800">記事</h1>
      <div className="row">
        <TopCard
          title="記事"
          text={articleList.length.toString()}
          icon="newspaper"
          class="danger"
        />
        <ButtonWrapper>
          <AddButton href="/articles/new">投稿</AddButton>
        </ButtonWrapper>
      </div>
      <div>
        <ArticleTable articles={articleList} />
      </div>
    </AdminTemplate>
  );
};

export default Articles;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 30px;
`;
