import React, { useEffect, useState } from "react";
import AdminTemplate from "./AdminTemplate";
import { TextField, Select, MenuItem } from "@material-ui/core/";
import { getAsync } from "../../utils/apiClient";
import { GetPlansResponse, Plan } from "../../common/types/apimodel";
import styled from "styled-components";
import ImageUploader from "../atoms/ImageUploader/ImageUploader";
import Button from "../atoms/Button/Button";
import { Image } from "../../hooks";
import { ArticleEditor } from "../organisms/ArticleEditor";
import { PlanSettingModal } from "../molecules/PlanSettingModal";
import { EditorState } from "draft-js";
import Modal from "react-modal";
import "../../styles/article-editor.css";
import { NotificationDialog } from "../molecules/Dialog";
import { useHistory } from "react-router-dom";

Modal.setAppElement("#root");

interface EditArticleTemplateProps {
  plans: Plan[];
  setPlans: React.Dispatch<React.SetStateAction<Plan[]>>;
  viewablePlanIds: string[];
  setViewablePlanIds: React.Dispatch<React.SetStateAction<string[]>>;
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  file: Image | null;
  setFile: (files: File[] | null) => void;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  disclosureRange: string;
  setDisclosureRange: React.Dispatch<React.SetStateAction<string>>;
  sendArticleAsync: () => Promise<void>;
}

const EditArticleTemplate: React.FC<EditArticleTemplateProps> = ({
  plans,
  setPlans,
  viewablePlanIds,
  setViewablePlanIds,
  editorState,
  setEditorState,
  file,
  setFile,
  title,
  setTitle,
  disclosureRange,
  setDisclosureRange,
  sendArticleAsync
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitCompletedModalOpen, setIsSubmitCompletedModalOpen] = useState(
    false
  );
  const history = useHistory();

  useEffect(() => {
    const getPlansAsync = async () => {
      const response = await getAsync<GetPlansResponse>("/plans");
      setPlans(response.data.plans);
    };
    getPlansAsync();
  }, [setPlans]);

  const handleOnSaveClick = () => {
    setIsModalOpen(true);
  };

  const handleOnDisclosureRangeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setDisclosureRange(String(event.target.value));
  };

  const handleOnTitleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setTitle(event.target.value);

  const handleOnSubmit = () => {
    sendArticleAsync().then(() => setIsSubmitCompletedModalOpen(true));
  };

  return (
    <AdminTemplate>
      <HeaderWrapper>
        <SubmitButtonDiv>
          <Select
            defaultValue={"draft"}
            onChange={handleOnDisclosureRangeChange}
          >
            <MenuItem value="draft">下書き</MenuItem>
            <MenuItem value="published">公開</MenuItem>
          </Select>
          <ButtonWrapper>
            <Button onClick={handleOnSaveClick} containerStyle={"info"}>
              プラン設定
            </Button>
          </ButtonWrapper>
        </SubmitButtonDiv>
        <ImageUploader file={file} setFile={setFile} />
      </HeaderWrapper>
      <TextField
        fullWidth
        name="title"
        label="タイトル"
        value={title}
        onChange={handleOnTitleChange}
      />
      <EditorDiv>
        <ArticleEditor
          editorState={editorState}
          setEditorState={setEditorState}
        />
      </EditorDiv>
      <Modal
        style={{
          overlay: { zIndex: 100 },
          content: {
            width: "60%",
            margin: "0 auto"
          }
        }}
        isOpen={isModalOpen}
      >
        <PlanSettingModal
          disclosureRange={disclosureRange}
          isArticleTitleFilled={title.trim() !== ""}
          isArticleBodyFilled={editorState.getCurrentContent().hasText()}
          plans={plans}
          viewablePlanIds={viewablePlanIds}
          setIsModalOpen={setIsModalOpen}
          setViewablePlanIds={setViewablePlanIds}
          onSubmintButtonClick={handleOnSubmit}
        />
      </Modal>
      <NotificationDialog
        onAgree={() => history.push("/articles")}
        notificationText={
          disclosureRange === "draft"
            ? "下書きが保存されました！"
            : "記事が公開されました！"
        }
        isOpen={isSubmitCompletedModalOpen}
        setIsOpen={setIsSubmitCompletedModalOpen}
      />
    </AdminTemplate>
  );
};

export default EditArticleTemplate;

const EditorDiv = styled.div`
  display: block;
  height: calc(100vh - 240px);
`;

const SubmitButtonDiv = styled.div`
  display: flex;
  margin-left: 82%;
`;

const HeaderWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 30px;
`;
