import React from "react";
import styled from "styled-components";

const Table: React.FC<TableElementProps> = ({ header, contents }) => {
  if (header.items.length === 0) return null;
  return (
    <TableRoot>
      <thead>
        <tr>
          <th scope="col">#</th>
          {header.items.map((item) => (
            <th key={item} scope="col">
              {item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {contents.map((content, i) => {
          return (
            <tr key={`row_${content.id}`}>
              <th scope="row">{i + 1}</th>
              {content.items.map((item, i) => (
                <td key={`col_${i}_${content.id}`}>{item}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </TableRoot>
  );
};

const TableRoot = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  text-align: left;
  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e3e6f0;
    color: #495057;
    background-color: #e9ecef;
    border-color: #e3e6f0;
  }
  th,
  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e3e6f0;
  }
`;

export interface TableContent {
  items: string[];
}

export interface TableRow {
  id: number | string;
  items: (string | JSX.Element)[];
}

interface TableElementProps {
  header: TableContent;
  contents: TableRow[];
}

export default Table;
